<script setup lang="ts">
  import Cross from '@/assets/icons/cross.svg'

  const onClose = () => {
    window.parent.postMessage({ modal: 'close', isWeWiink: true }, '*')
  }

  const { shop, ref: referralId, langcode, currencycode, currencyrate } = useRoute().query
  const storeStore = useStoreStore()
  const isReady = ref(false)

  onBeforeMount(() => {
    useAppLocale().applyLocale()
  })

  onMounted(async () => {
    const company = await storeStore.fetchStoreFromName(shop as string)
    if (!company || !referralId) {
      onClose()
      return
    }
    useStoreApp().companyId = company.company_id

    await storeStore.fetchLocales(company.company_id)

    storeStore.langCode = langcode as string
    storeStore.currencyCodeExtension = (currencycode as string) || null
    storeStore.currencyRate = !isNaN(parseFloat((currencyrate as string) ?? ''))
      ? parseFloat((currencyrate as string) ?? '')
      : 1.0

    isReady.value = true
  })
</script>

<template>
  <div
    v-if="isReady"
    class="layout-modal"
  >
    <button
      class="layout-modal--button"
      @click.stop="onClose"
    >
      <Cross />
    </button>
    <slot />
  </div>
</template>

<style scoped>
  .layout-modal {
    width: inherit;
    position: relative;

    &--button {
      position: absolute;
      display: flex;
      cursor: pointer;
      padding: 6px;
      top: 20px;
      right: 20px;
      z-index: 1;
      border-radius: 20px;
      background: rgba(255, 255, 255, 0.5);
    }
  }
</style>
